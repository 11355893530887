import React, {useCallback, useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight, faEllipsisH} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";
import {TransactionService} from "../../../../services/transaction.service";
import {UserContext} from "../../../../context/UserContext";
import {CircleFlag} from "react-circle-flags";
import {ITransactionObjectResponse} from "../../../../services/responses/dashboard.responses";
import {TransactionType} from "../../../../services/responses/objects/TransactionType";
import {filter} from "jszip";

type TransactionTableComponentProp = {
    openTransaction: (state: ITransactionObjectResponse) => void;
    search: string,
    filterType: string,
    latestPurchaseId: string | undefined
};

const TransactionTableComponent: React.FC<TransactionTableComponentProp> = ({ openTransaction, search, filterType, latestPurchaseId}: TransactionTableComponentProp)  => {

    const { user } = useContext(UserContext);
    const [tableLoading, setTableLoading] = useState<boolean>(true);
    const [page, setPage] = useState<number>(1);
    const [offset, setOffset] = useState<number>(1);
    const [maxPages, setMaxPage] = useState<number>(0);
    const [transactions, setTransactions] = useState<ITransactionObjectResponse[]>([]);
    const [debouncedSearch, setDebouncedSearch] = useState<string>(search);

    const customId = "custom-simly-toast";
    const toastOptions = {
        position: toast.POSITION.TOP_CENTER,
        toastId: customId,
        autoClose: 3000,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        draggable: false
    };

    // Debounce Effect for Search
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearch(search);
        }, 1000);

        // Clear the timeout if the component is unmounted or the search value changes
        return () => clearTimeout(handler);
    }, [search]);

    const fetchSummary = async () => {
        setTableLoading(true);
        const data = await TransactionService.getPageTransactions(debouncedSearch, filterType, page, 10);

        if (data.data.contents.length === 0) {
            setMaxPage(1);
            setTransactions([]);
        } else {
            setMaxPage(data.data.metadata.totalPages);
            setTransactions(data.data.contents);
        }

        setTableLoading(false);
    };

    useEffect(() => {
        fetchSummary().catch(() => toast.error('Unable to fetch the data from source.', toastOptions));
    }, [page, debouncedSearch]);

    useEffect(() => {
        resetTable();
    }, [latestPurchaseId, filterType]);

    const resetTable = async () => {
        setPage(1);
        setOffset(1);
        setMaxPage(0);

        await fetchSummary().catch(() => toast.error('Unable to fetch the data from source.', toastOptions));
    };

    const range = function(start: number, end: number, step: number) {
        const result = [];
        for (let i = start; i <= end; i += step) {
            result.push(i);
        }
        return result;
    };

    return (
        <div className={"w-full h-full flex flex-col sm:items-center sm:justify-center sm:mt-5 overflow-x-auto"}>
            <div className="w-[44rem] sm:w-11/12 border border-gray-200 flex flex-col items-center justify-center rounded-lg mt-14 mb-7">
                <table className="w-full table-auto border-collapse">
                    <thead>
                    <tr className="border-b border-gray-200">
                        <th className="text-xs lg:text-sm text-gray-500 font-semibold px-3 py-2 text-left">Transaction ID</th>
                        <th className="text-xs lg:text-sm text-gray-500 font-semibold px-3 py-2 text-left">Transaction Date</th>
                        <th className="text-xs lg:text-sm text-gray-500 font-semibold px-3 py-2 text-left">ICCID</th>
                        <th className="text-xs lg:text-sm text-gray-500 font-semibold px-3 py-2 text-left">Package</th>
                        <th className="text-xs lg:text-sm text-gray-500 font-semibold px-3 py-2 text-left">Email</th>
                        <th className="text-xs lg:text-sm text-gray-500 font-semibold px-3 py-2 text-left">Amount</th>
                        <th className="text-xs lg:text-sm text-gray-500 font-semibold px-3 py-2 text-left">Paid</th>
                        <th className="text-xs lg:text-sm text-gray-500 font-semibold px-3 py-2 text-left">Received</th>
                        <th className="text-xs lg:text-sm text-gray-500 font-semibold px-3 py-2 text-left">Type</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tableLoading ? (
                        [...Array(8)].map((_, i) => (
                            <tr key={i} className="border-b border-gray-200">
                                <td className="px-3 py-4">
                                    <div className="w-[75%] animate-pulse bg-gray-200 rounded h-4 sm:h-8"></div>
                                </td>
                                <td className="px-3 py-4">
                                    <div className="w-[75%] animate-pulse bg-gray-200 rounded h-4 sm:h-8"></div>
                                </td>
                                <td className="px-3 py-4">
                                    <div className="w-[75%] animate-pulse bg-gray-200 rounded h-4 sm:h-8"></div>
                                </td>
                                <td className="px-3 py-4">
                                    <div className="w-[75%] animate-pulse bg-gray-200 rounded h-4 sm:h-8"></div>
                                </td>
                                <td className="px-3 py-4">
                                    <div className="w-[75%] animate-pulse bg-gray-200 rounded h-4 sm:h-8"></div>
                                </td>
                                <td className="px-3 py-4">
                                    <div className="w-[75%] animate-pulse bg-gray-200 rounded h-4 sm:h-8"></div>
                                </td>
                                <td className="px-3 py-4">
                                    <div className="w-[75%] animate-pulse bg-gray-200 rounded h-4 sm:h-8"></div>
                                </td>
                                <td className="px-3 py-4">
                                    <div className="w-[75%] animate-pulse bg-gray-200 rounded h-4 sm:h-8"></div>
                                </td>
                                <td className="px-3 py-4">
                                    <div className="flex justify-center animate-pulse bg-gray-200 rounded h-4 sm:h-8 w-[75%]"></div>
                                </td>
                            </tr>
                        ))
                    ) : (
                        transactions.map((entry) => (
                            <tr key={entry.transaction.id} className="border-b border-gray-200">
                                <td className="text-xs lg:text-sm text-black font-semibold px-3 py-4">{entry.transaction.id}</td>
                                <td className="text-xs lg:text-sm text-black font-semibold px-3 py-4">{entry.transaction.dateCreated}</td>
                                <td className="text-xs lg:text-sm text-black font-semibold px-3 py-4">{entry.esim.iccid}</td>
                                <td className="text-xs lg:text-sm text-black font-semibold px-3 py-4">
                                    <img alt={entry.country.isoCode} src={entry.country.countryImageURL} className="rounded-full w-4 h-4 lg:w-5 lg:h-5 inline-block"/>
                                    <span className="pl-3">{entry.country.name} - {entry.plan.name}</span>
                                </td>
                                <td className="text-xs lg:text-sm text-black font-semibold px-3 py-4">{entry.user ? entry.user.email : "-"}</td>
                                <td className="text-xs lg:text-sm text-black font-semibold px-3 py-4">${entry.transaction.initial_price}</td>
                                <td className="text-xs lg:text-sm text-black font-semibold px-3 py-4">${entry.transaction.price}</td>
                                <td className="text-xs lg:text-sm text-black font-semibold px-3 py-4">{entry.transaction.received == 0 ? '-' : '$' + entry.transaction.received}</td>
                                <td className="py-4">
                                    <div className={"flex flex-row items-center justify-center"}>
                                        {entry.type === TransactionType.ESIM ?
                                            <div className={"w-20 h-6 lg:w-24 lg:h-9 bg-green-100 flex flex-row justify-center items-center rounded-lg"}>
                                                <p className={"text-green-600 font-semibold text-xs lg:text-sm"}>New eSIM</p>
                                            </div> :
                                            <div className={"w-20 h-6 lg:w-24 lg:h-9 bg-orange-100 flex flex-row justify-center items-center rounded-lg"}>
                                                <p className={"text-orange-400 font-semibold text-xs lg:text-sm"}>Top Up</p>
                                            </div>}

                                        <FontAwesomeIcon className={"pl-8 text-gray-400 font-bold cursor-pointer"} icon={faEllipsisH} onClick={() => {
                                            openTransaction(entry)
                                        }}/>
                                    </div>
                                </td>
                            </tr>
                        ))
                    )}
                    </tbody>
                </table>
            </div>


            <div className={"w-11/12 h-16 flex flex-row-reverse"}>
                {maxPages === 0 ? <div className={"w-1/4 sm:w-2/6 lg:w-1/6 sm:h-8 h-full animate-pulse bg-gray-200 rounded"}></div> :
                    <div className={"flex flex-row-reverse sm:w-2/6 lg:w-1/6 h-8 justify-between items-center"}>
                        <div className={"w-7 h-8 lg:w-8 lg:h-9 flex flex-row items-center justify-center bg-white cursor-pointer"}>
                            <FontAwesomeIcon className={`${offset + 5 > maxPages ? 'text-gray-200 font-bold' : 'text-gray-500 opacity-50'}`} icon={faChevronRight}
                                             onClick={() => {
                                                 if(offset + 5 <= maxPages)
                                                     setOffset(offset + 1)
                                             }}/>
                        </div>

                        {range(offset, offset + 4, 1).reverse().map(val =>
                            <div key={val} className={`w-7 h-8 lg:w-8 lg:h-9 flex flex-row items-center justify-center ${val === page ? 'bg-gray-100 rounded-lg' : 'bg-white'} cursor-pointer`} onClick={
                                () => {
                                    if(val !== page)
                                        setPage(val)
                                }
                            }>
                                <p className={`text-gray-900 font-bold ${val !== page ? 'opacity-50' : ''}`}>{val}</p>
                            </div>
                        )}

                        <div className={"w-7 h-8 lg:w-8 lg:h-9 flex flex-row items-center justify-center bg-white cursor-pointer"}>
                            <FontAwesomeIcon className={`${offset === 1 ? 'text-gray-200 font-bold' : 'text-gray-500 opacity-50'}`} icon={faChevronLeft}
                                             onClick={() => {
                                                 if(offset > 1)
                                                     setOffset(offset - 1)
                                             }}/>
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}

export default TransactionTableComponent;
