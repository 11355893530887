import React, {useContext, useEffect, useState} from "react";
import {UserContext} from "../../../../../context/UserContext";
import {confirmAlert} from "react-confirm-alert";
import PurchaseConfirmationAlert from "../../generate/alert/PurchaseConfirmationAlert";
import {
    CardCvcElement,
    CardElement,
    CardExpiryElement,
    CardNumberElement,
    Elements,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import {loadStripe, StripeCardElement} from "@stripe/stripe-js";
import {STRIPE_PUBLIC_KEY} from "../../../../../utils/config";
import {toast} from "react-toastify";
import {BalanceService} from "../../../../../services/balance.service";
import {CardResponse} from "../../../../../services/responses/dashboard.responses";
import DashboardPanel from "../../DashboardPanel";
import {TopUpOptions, UserDTO} from "../../../../../dto/UserDTO";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faX} from "@fortawesome/free-solid-svg-icons";
import {Simulate} from "react-dom/test-utils";
import load = Simulate.load;

type TopupBalanceTabProp = {
    isTabOpen: boolean;
    setTabOpen: (state: boolean) => void;
};

const customId = "custom-simly-toast";

const toastOptions = {
    position: toast.POSITION.TOP_RIGHT,
    toastId: customId,
    autoClose: 3000,
    closeOnClick: true,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    draggable: false
}

const TopupBalanceTabComponent: React.FC<TopupBalanceTabProp> = ({ isTabOpen, setTabOpen }: TopupBalanceTabProp) => {

    const {user, setUser} = useContext(UserContext);
    const [topupAmount, setTopupAmount] = useState<number>(0);

    const [card, setCard] = useState<CardResponse | null>(null);
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [outcome, setOutcome] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [topupOptions, setTopUpOptions] = useState<TopUpOptions>(user!.autoTopUp);

    const elements = useElements();
    const stripe = useStripe();

    useEffect(() => {
        BalanceService.getCard().then(r => {
            setCard(r.data);
        })
    }, [])

    const handleSubmit = async (e: any) => {
        if(loading) {
            return;
        }

        e.preventDefault();
        setLoading(true);

        if (!name) {
            setLoading(false);
            toast.error('The card holder name is invalid.', toastOptions)
            return;
        }

        toast.loading("Attempting to add card.")

        try {
            const { token, error } = await stripe!.createToken(elements!.getElement(CardNumberElement)!, { name });

            if(error) {
                toast.error(`An error occurred whilst saving your card: ${error.code}`, toastOptions);
            } else if(token) {
                const cardUpdate = await BalanceService.updateCard(token.id);
                setCard(cardUpdate.data);

                toast.success("Successfully added card.")
            }
        } catch (error) {
            toast.error(`An error occurred whilst saving your card: ${error}`, toastOptions);
        } finally {
            setLoading(false);
            setIsEditing(false);
        }
    };

    const topupAccount = async () => {
        if(loading) {
            return;
        }

        setLoading(true);

        if(topupAmount <= 1 || topupAmount > 1000) {
            toast.error('You can only topup more than 1$ or under 1000$.', toastOptions)
            setLoading(false)
        }

        toast.loading("Topping up account..");

        const topupResponse = await BalanceService.topupAmount(topupAmount);

        console.log(topupResponse);

        if(topupResponse.code != 200) {
            if(topupResponse.code != 201)
                toast.error(topupResponse.message, toastOptions);
            else
                toast.warning(topupResponse.message, toastOptions);
        } else {
            toast.success(topupResponse.message, toastOptions);

            setUser({
                ...user as UserDTO,
                balance: topupAmount + user!.balance
            })
        }

        setLoading(false);
        setTopupAmount(0);
    }

    const saveTopUpOptions = async () => {
        if(loading) {
            return;
        }

        setLoading(true);

        if(topupOptions.threshold <= 10 || topupOptions.amount <= 1) {
            toast.error('You can only topup more than 1$ with a threshold of at least 10$.', toastOptions)
            setLoading(false)
        }

        toast.loading("Saving topup options..")
        const topupResponse = await BalanceService.updateTopUpOptions(topupOptions);

        if(topupResponse.code != 200) {
            toast.error(topupResponse.message, toastOptions)
        } else {
            toast.success(topupResponse.message, toastOptions);
            setUser({
                ...user as UserDTO,
                autoTopUp: topupResponse.data
            })
        }

        setLoading(false);
    }

    const renderCardDetails = () => {
        if (isEditing) {
            return (
                <div className="flex flex-col h-2/3 w-10/12 content-between justify-between">
                    <div>
                        <p className="font-inter font-semibold text-[#FFFFFF] text-sm opacity-70">Card Holder</p>
                        <input
                            type="text"
                            id="card-holder"
                            className="font-inter font-bold text-white text-bold text-base 2xl:text-xl bg-transparent"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>

                    <div className="pt-2.5">
                        <label htmlFor="card-number" className="font-inter font-semibold text-white text-sm opacity-70">
                            Card Number
                        </label>
                        <CardNumberElement className={"font-inter font-bold"}
                            options={{
                                style: {
                                    base: {
                                        iconColor: '#fff',
                                        color: '#fff',
                                        lineHeight: '40px',
                                        fontWeight: 600,
                                        fontFamily: "Inter",
                                        fontSize: '14px',
                                        '::placeholder': {
                                            color: '#CFD7E0',
                                        },
                                    },
                                },
                                showIcon: true,
                            }}
                        />
                    </div>

                    <div className="flex h-full w-full items-center justify-center">
                        <div className="flex flex-col h-2/6 w-2/6 justify-center items-start">
                            <p className="font-inter font-semibold text-[#FFFFFF] text-sm opacity-70">Valid Thru</p>
                            <CardExpiryElement className={"w-full h-full font-inter"} options={{
                                style: {
                                    base: {
                                        iconColor: '#fff',
                                        color: '#fff',
                                        lineHeight: '40px',
                                        fontWeight: 600,
                                        fontFamily: "Inter",
                                        fontSize: '14px',
                                        '::placeholder': {
                                            color: '#CFD7E0',
                                        },
                                    },
                                }
                            }}/>
                        </div>
                        <div className="flex flex-col h-2/6 w-2/6 justify-center items-start">
                            <p className="font-inter font-semibold text-[#FFFFFF] text-sm opacity-70">CVC</p>
                            <CardCvcElement className={"w-full h-full"} options={{
                                style: {
                                    base: {
                                        iconColor: '#fff',
                                        color: '#fff',
                                        lineHeight: '40px',
                                        fontWeight: 600,
                                        fontFamily: "Inter",
                                        fontSize: '14px',
                                        '::placeholder': {
                                            color: '#CFD7E0',
                                        },
                                    },
                                }
                            }}/>
                        </div>
                        <div className="flex flex-col h-2/3 w-2/3 items-end justify-center">
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="flex flex-col h-2/3 w-10/12 content-between justify-between">
                    <div>
                        <p className="font-inter font-semibold text-[#FFFFFF] text-sm opacity-70">Card Holder</p>
                        <p className="font-inter font-bold text-white text-bold text-base 2xl:text-xl">{card?.name}</p>
                    </div>

                    <div className="pt-2.5">
                        <p className="font-inter font-semibold text-[#FFFFFF] text-sm opacity-70">Card Number</p>
                        <p className="font-inter font-bold text-white text-base 2xl:text-xl">**** **** **** {card?.lastFour ? card?.lastFour : "****" }</p>
                    </div>

                    <div className="flex  h-full w-full items-center justify-center">
                        <div className="flex flex-col h-2/6 w-3/6 justify-center items-start">
                            <p className="font-inter font-semibold text-[#FFFFFF] text-sm opacity-70">Valid Thru</p>
                            <div className={"flex flex-row items-center"}>
                                <p className="font-inter font-bold text-white text-base 2xl:text-lg">{card?.expiryMonth ? card?.expiryMonth : "**" }</p>
                                <img src={"./icons/slash-marker.svg"} className={"w-2 pb-1"}/>
                                <p className="font-inter font-bold text-white text-base 2xl:text-lg">{card?.expiryYear ? card?.expiryYear : "**" }</p>
                            </div>
                        </div>
                        <div className="flex flex-col h-2/6 w-2/6 justify-center items-start">
                            <p className="font-inter font-semibold text-[#FFFFFF] text-sm opacity-70">CVC</p>
                            <p className="font-inter font-bold text-white text-base 2xl:text-lg">***</p>
                        </div>
                        <div className="flex flex-col h-2/3 w-2/3 items-end justify-center">
                        </div>
                    </div>
                </div>
            );
        }
    };

    return (
        <>
            <div className={`mt-5 flex flex-col shrink-0 h-64 2xl:h-80 w-full justify-start items-center bg-gradient-to-b from-[#f68959] to-[#fc9e40] rounded-4xl transition ease-in-out duration-300`}>
                <div className="flex h-1/3 w-10/12 justify-between items-center">
                    <img src={"./icons/simly-white.svg"} className={"w-28"}/>
                    {isEditing ?
                        <div className={"flex flex-row items-center gap-5"}>
                            <FontAwesomeIcon className={"text-white"} size={"xl"} icon={faX} onClick={() => setIsEditing(false)}/>
                            <img src={"./icons/confirm.svg"} className={"w-7 h-7 cursor-pointer"}
                                 onClick={handleSubmit}/>
                        </div> :
                        <img src={"./icons/edit.svg"} className={"w-7 h-7 cursor-pointer"}
                                                      onClick={() => setIsEditing(true)}/>
                }
            </div>

                {renderCardDetails()}
            </div>

            <div className={"flex flex-col h-24 w-full justify-between mt-8"}>
                <p className={"text-base font-inter font-medium text-[#030229]"}>Current Balance</p>
                <input type="text" disabled value={"$" + user?.balance}
                       className="h-14 w-full rounded-xl bg-[#F7F7F8] focus:outline-none pl-5 text-[#4C4B67]"
                       placeholder="balance" aria-label="balance"/>
            </div>
            <div className={"flex flex-col h-24 w-full justify-between mt-7"}>
                <p className={"text-base font-inter font-medium text-[#030229]"}>Amount</p>
                <div className={"flex flex-row items-center w-full h-14 rounded-xl bg-[#F7F7F8] focus:outline-none pl-5 text-[#4C4B67]"}>
                    <p className="">$</p>
                    <input
                        type="number"
                        value={topupAmount}
                        onChange={(e) => setTopupAmount(Number(e.target.value))}
                        className="flex-grow bg-[#F7F7F8] focus:outline-none text-[#4C4B67]"
                        placeholder="topup"
                        aria-label="topup"
                    />
                </div>
            </div>

            <button className={`flex flex-row w-full shrink-0 h-12 bg-[#5986FA] rounded-2xl my-8 items-center justify-center`} onClick={() => {
                topupAccount()
            }}>
                <p className={"text-xl text-white font-inter font-medium"}>Top Up</p>
            </button>
            <div className={"flex flex-col w-full justify-between mt-2"}>
                <div className={"flex flex-row items-center justify-between my-5"}>
                    <p className={"text-base font-inter font-medium text-[#030229]"}>Enable Auto Topup</p>
                    <label className="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox" value="" className="sr-only peer" checked={topupOptions.enabled} onChange={(e) => setTopUpOptions({
                            ...topupOptions,
                            enabled: !topupOptions.enabled
                        })}/>
                        <div className="w-11 h-6 bg-[#E71D36]/80 focus:outline-none rounded-full peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#F7F7F8] after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#57E165] "></div>
                    </label>
                </div>
                <p className={"text-[#333333] font-inter w-[95%] mb-5"}>Balance will be topped up automatically once it drops below the threshold.</p>
                <p className={"text-base font-inter font-medium text-[#030229] my-2"}>Auto Top Up Threshold</p>
                <div className={"flex flex-row items-center w-full h-14 rounded-xl bg-[#F7F7F8] focus:outline-none pl-5 text-[#4C4B67] mb-2"}>
                    <p className="">$</p>
                    <input
                        type="number"
                        value={topupOptions.threshold}
                        onChange={(e) => setTopUpOptions({
                            ...topupOptions,
                            threshold: Number(e.target.value),
                        })}
                        className="flex-grow bg-[#F7F7F8] focus:outline-none text-[#4C4B67]"
                        placeholder="topup"
                        aria-label="topup"
                    />
                </div>
                <p className={"text-base font-inter font-medium text-[#030229] my-2"}>Auto Top Up Amount</p>
                <div className={"flex flex-row items-center w-full h-14 rounded-xl bg-[#F7F7F8] focus:outline-none pl-5 text-[#4C4B67]"}>
                    <p className="">$</p>
                    <input
                        type="number"
                        value={topupOptions.amount}
                        onChange={(e) => setTopUpOptions({
                            ...topupOptions,
                            amount: Number(e.target.value),
                        })}
                        className="flex-grow bg-[#F7F7F8] focus:outline-none text-[#4C4B67]"
                        placeholder="topup"
                        aria-label="topup"
                    />
                </div>
            </div>

            <button className={`flex flex-row w-full shrink-0 h-12 bg-[#5986FA] rounded-2xl my-8 items-center justify-center`} onClick={() => {
                saveTopUpOptions()
            }}>
                <p className={"text-xl text-white font-inter font-medium"}>Save Settings</p>
            </button>
        </>
    );
}

export default TopupBalanceTabComponent;
