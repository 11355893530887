import {getAccessToken, SIMLY_PARTNER_API_URL} from "../utils/config";
import {IDashboardSummary, ITransactionResponse} from "./responses/dashboard.responses";
import {BasicResponse} from "./responses/basic.response";

export class TransactionService {

    public static getDashboardSummary = async (): Promise<BasicResponse<IDashboardSummary>> => {
        const resp = await fetch( SIMLY_PARTNER_API_URL + "/dashboard/summary", {
            method: "GET",
            headers: {
                'x-simly-token': getAccessToken()!,
                'Content-Type': 'application/json'
            },
        });

        return await resp.json() as BasicResponse<IDashboardSummary>;
    }

    public static getPageTransactions = async (search: string, filterType: string, page: number, size: number): Promise<BasicResponse<ITransactionResponse>> => {
        const resp = await fetch( SIMLY_PARTNER_API_URL + `/dashboard/transactions?search=${search}&page=${page}&size=${size}&filter=${filterType}`, {
            method: "GET",
            headers: {
                'x-simly-token': getAccessToken()!,
                'Content-Type': 'application/json'
            }
        });

        return await resp.json() as BasicResponse<ITransactionResponse>;
    }

    public static exportCSV = async (startDate: string, endDate: string) => {
        const response = await fetch(SIMLY_PARTNER_API_URL + `/dashboard/csv?startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}`, {
            method: 'GET',
            headers: {
                'x-simly-token': getAccessToken()!,
                'Content-Type': 'application/json',
            },
        });

        if(response.ok) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;

            const base64Start = btoa(startDate);
            const base64End = btoa(endDate);
            link.download = `simly-transactions-${base64Start}-${base64End}.csv`;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            throw new Error(await response.text())
        }
    }
}
